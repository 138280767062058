.title {
  font-size: 1.8vw;
  font-weight: 800;
  color: #000;
}
.text {
  font-size: 0.9vw;
  margin: 0;
}
.icon {
  width: auto;
  height: auto;
  width: 3.8vw;
  height: 3.8vw;
  flex-shrink: 0;
}
.icon > img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 1.9rem;
  }
  .text {
    font-size: 1rem;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
}
