.trapezoid {
  -webkit-clip-path: polygon(0 0, 100% 20%, 100% 80%, 0% 100%);
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
  /* filter: drop-shadow(0 0 40px #222); */

  /* box-shadow: 0px 0px 13px 0px black; */
}
.separator {
  border-right: 1px solid #b3b3b3;
  height: 90%;
}

.title {
  font-size: 3.5vw;
  line-height: 1.1;
  font-weight: 800;
  margin-bottom: 0;
}
.secondaryTitle {
  font-size: 2.7vw;
  line-height: 1.1;
  font-weight: 800;
  margin-bottom: 0;
}
.text {
  font-size: 1vw;
}
.secondaryText {
  font-size: 0.7vw;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 1.9rem;
  }
  .text {
    font-size: 1rem;
  }
  .secondaryText {
    font-size: 0.7rem;
  }
  .secondaryTitle {
    font-size: 1.6rem;
  }
}
