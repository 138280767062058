.tableContainer {
  min-height: 500px;
  overflow: auto;
}

.table {
  width: 100%;
  color: #000;
  table-layout: fixed;

  overflow: auto;
}
.table,
.table * {
  white-space: nowrap;
}
.table,
.table thead,
.table tbody,
.table tr {
  /* display: block;
  width: 100%; */
}
.table thead {
  background-color: #af0001;
  color: #fff;
}
.table td {
  color: #151515;

  opacity: 0.9;
}
.table th {
  width: 250px;
  min-width: 250px;
}
.table td {
  width: 250px;
  min-width: 250px;
}
.table tr,
.table th,
.table td {
  font-weight: 500;
}
.table td,
.table th {
  height: 100%;
  padding: 15px;
  border: 0;

  text-align: start;
  white-space: nowrap;
  display: inline-block;
}

.table tr:nth-child(even) {
  background-color: #fcf5f6;
}
/* .table tr:first-child th {
  border-top: 0;
}
.table tr:last-child td {
  border-bottom: 0;
}
.table tr td:first-child,
.table tr th:first-child {
  border-left: 0;
}
.table tr td:last-child,
.table tr th:last-child {
  border-right: 0;
} */

@media (max-width: 992px) {
  .table td,
  .table th {
    display: table-cell;
  }
}
