.mainPageContainer {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  height: calc(100vh - 178px);
  /* overflow: auto; */
}
@media only screen and (max-width: 992px) {
  .mainPageContainer {
    height: auto;
  }
}
