.ag-theme-quartz {
  --ag-header-background-color: #af0001;
  --ag-active-color: #e4e4e4;
  --ag-header-foreground-color: white;
  --ag-header-column-resize-handle-color: white;
  --ag-icon-font-color: #000;
}
.ag-theme-quartz .ag-header-cell-label {
  font-weight: 700;
}
