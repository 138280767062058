.tree-select .ant-select-selector {
  min-height: 40px;
}

.progress-bar-success {
  background-color: #00ca4e;
}
.progress-bar-warning {
  background-color: #ffbd44;
}
.progress-bar-danger {
  background-color: #ff605c;
}

/* Full  Calendar */
.fc-daygrid-event {
  white-space: normal !important;
  align-items: normal !important;
}
